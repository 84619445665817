import { GenversePageLink } from '../../../types';

export type Event = {
    anonymousId: string;
    address: string;
    name: string;
    path: string | null;
    url: string | null;
    referrer: string | null;
    search: string | null;
} & EventProperties;

export type EventProperties = {
    address?: string;
    pageLink?: GenversePageLink;
};

export const CLICKED_METAPAGE_LINK_EVENT = 'Clicked MetaPage Link';
const ENABLED_EVENTS = {
    [CLICKED_METAPAGE_LINK_EVENT]: {
        requiredFields: [
            'anonymousId',
            'address',
            'name',
            'path',
            'url',
            'referrer',
            'search',
        ],
    },
} as any;
export default () => {
    // As we use event listeners we access data via the ref
    const trackEvent = (eventName: string, properties?: EventProperties) => {
        if (!ENABLED_EVENTS?.[eventName])
            throw `${eventName} not enabled/allowed!`;

        const enrichedProperties = {
            ...properties,
            anonymousId:
                // @ts-ignore
                window?.analytics?.user &&
                // @ts-ignore
                window.analytics.user().anonymousId(),
            name: eventName,
            path: location.pathname,
            url: location.href,
            referrer: location.href,
            search: location.search,
        };

        const missingRequiredFields = ENABLED_EVENTS[
            eventName
        ].requiredFields.filter((requiredField: string) => {
            return !(requiredField in enrichedProperties);
        });

        if (missingRequiredFields?.length === 0) {
            // @ts-ignore
            if (window?.analytics) {
                // @ts-ignore
                window.analytics.track(eventName, enrichedProperties);
            }
        }
    };

    return {
        trackEvent,
    };
};
