import * as React from 'react';
import { Router } from '@reach/router';
import MetaPage from '../../components/MetaPage';
import QuickViewNFTProvider from '../../components/MetaPage/contexts/QuickViewNFTContext';
import G3TokenGateProvider from '../../components/Dashboard/contexts/G3TokenGateContext';

export default () => {
    return (
        <G3TokenGateProvider>
            <QuickViewNFTProvider>
                <Router basepath="/page">
                    {/*@ts-ignore*/}
                    <MetaPage path="/:walletAddress" />
                </Router>
            </QuickViewNFTProvider>
        </G3TokenGateProvider>
    );
};
