import React from 'react';
import copy from 'copy-to-clipboard';
import toast from 'react-hot-toast';
import useGenversePage from '../../../../hooks/useGenversePage';
import useENSName from '../../../../hooks/useENSName';
import { useMoralis } from 'react-moralis';
import shortenAddress from '../../../../utils/shortenAddress';
import G3DropdownMenu from '../../../general/G3DropdownMenu';

export default ({ walletAddress }: { walletAddress: string }) => {
    const { Moralis } = useMoralis();
    const { genversePage, isCurrentUser } = useGenversePage({
        address: walletAddress,
    });
    const { ensName, address } = useENSName(walletAddress);

    const menuItems = [
        {
            show: true,
            label: 'Copy Page Link',
            onClick: () => {
                copy(`${window?.location?.origin}/${ensName || address}`);
                toast.success('Page link copied!', {
                    style: {
                        background: '#333',
                        color: '#fff',
                    },
                });
            },
        },
        {
            show: !isCurrentUser,
            label: 'Send ETH',
            onClick: () => {
                Moralis.transfer({
                    type: 'native',
                    amount: Moralis.Units.ETH('0.123'),
                    receiver: address,
                });
            },
        },
    ];

    const filteredMenuItems = menuItems.filter(({ show }) => !!show);

    return (
        <G3DropdownMenu menuItems={filteredMenuItems}>
            {genversePage?.ensName || shortenAddress(genversePage?.address)}
        </G3DropdownMenu>
    );
};
