import React, { useEffect, useState } from 'react';
import { AspectRatio, Box, Flex, Text } from '@chakra-ui/react';
import CopyClipboardWrapper from '../../../general/CopyClipboardWrapper';
import {
    GenverseTokenMetadataType,
    GenverseTokenType,
} from '../../../../../../types';
import shortenAddress from '../../../../utils/shortenAddress';
import Lightbox from 'react-image-lightbox';
import NFTImage from '../../../general/NFTImage';
import useGenversePage from '../../../../hooks/useGenversePage';
import { navigate } from 'gatsby';
import PageNameMenu from '../PageNameMenu';
import Tile from '../../../base/Tile';

export default ({ walletAddress }: { walletAddress: string }) => {
    const { genversePage } = useGenversePage({ address: walletAddress });

    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [pageBioText, setPageBioText] = useState(genversePage?.page?.bio);
    const [pageToken, setPageToken] = useState(
        undefined as GenverseTokenType | undefined
    );

    useEffect(() => {
        setPageToken(
            genversePage?.page?.primaryToken || genversePage?.tokens?.[0]
        );

        if (genversePage?.page?.bio !== pageBioText)
            setPageBioText(genversePage?.page?.bio);
    }, [genversePage]);

    const animationSrc = pageToken?.metadata?.animationUrl as string;
    const imageSrc = pageToken?.metadata?.image as string;
    const src = animationSrc || imageSrc;

    return (
        <Flex direction="column" align="center" justify="center">
            <AspectRatio w={['15rem', '20rem']} ratio={1}>
                <Box
                    width="100%"
                    height="100%"
                    cursor="pointer"
                    _hover={{
                        opacity: 0.6,
                    }}
                    position="relative"
                >
                    <NFTImage
                        borderRadius={`50%`}
                        token={
                            {
                                metadata:
                                    pageToken?.metadata as GenverseTokenMetadataType,
                            } as GenverseTokenType
                        }
                        alt={genversePage?.ensName}
                        margin="1rem"
                        onClick={() => {
                            if (
                                !!pageToken?.chain &&
                                !!pageToken?.tokenAddress &&
                                !!pageToken?.tokenId
                            ) {
                                navigate(
                                    `/token/${pageToken.chain}/${pageToken.tokenAddress}/${pageToken.tokenId}`
                                );
                            }
                        }}
                    />
                </Box>
            </AspectRatio>

            <PageNameMenu walletAddress={walletAddress} />

            {!!genversePage?.ensName && (
                <CopyClipboardWrapper
                    copyText={genversePage?.address?.toLowerCase()}
                >
                    <Text textAlign="center" fontSize="0.8rem">
                        {`${shortenAddress(
                            genversePage?.address?.toLowerCase()
                        )}`}
                    </Text>
                </CopyClipboardWrapper>
            )}

            {/* eslint-disable-next-line react/jsx-no-undef */}
            <Tile padding="1rem 2rem" margin="1rem 0" width={['90vw', 'auto']}>
                <Text
                    width="100%"
                    fontSize={['1rem', '1.2rem']}
                    textAlign="center"
                    whiteSpace="break-spaces"
                >
                    {pageBioText ||
                        (genversePage?.connected ? 'Member' : 'Unclaimed')}
                </Text>
            </Tile>

            {lightboxOpen && (
                <Lightbox
                    mainSrc={src}
                    nextSrc={animationSrc ? imageSrc : ''}
                    onCloseRequest={() => setLightboxOpen(false)}
                />
            )}
        </Flex>
    );
};
