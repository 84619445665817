import React from 'react';
import Layout from '../base/Layout';
import {Box} from '@chakra-ui/react';
import {isEmpty} from 'lodash';
import PageHeader from './core/PageHeader';
import PageExternalLinks from './core/PageExternalLinks';
import useGenversePage from '../../hooks/useGenversePage';
import WalletNFTGrid from './core/WalletNFTGrid';
import useENSName from '../../hooks/useENSName';
import {BackgroundColoursTypes} from '../../../../types';
import G3lyticsPageTracker from '../../g3lytics/contexts/components/G3lyticsPageTracker';
import G3LogoSpinner from "../general/G3LogoSpinner";

export default ({ walletAddress }: { walletAddress: string }) => {
    const { genversePage, loading } = useGenversePage({address: walletAddress});
    const { address } = useENSName(walletAddress);

    return (
        <G3lyticsPageTracker
            pageEventName="MetaPage"
            pageChangeProperties={{ address }}
        >
            <Layout
                showNavBarLogo
                showSearchBar
                showNavLinks
                showConnectButton
                bgColor={
                    genversePage?.page?.theme
                        ?.backgroundTheme as BackgroundColoursTypes
                }
            >
                {loading && (
                    <G3LogoSpinner
                        containerProps={{
                            h: '20vh'
                        }}
                    />
                )}

                {!loading && !isEmpty(genversePage) && (
                    <>
                        <Box marginTop="3rem">
                            <PageHeader walletAddress={walletAddress} />
                        </Box>
                        <Box marginTop="1rem">
                            <PageExternalLinks walletAddress={walletAddress} />
                        </Box>
                        <Box marginTop="3rem">
                            <WalletNFTGrid walletAddress={walletAddress} />
                        </Box>
                    </>
                )}
            </Layout>
        </G3lyticsPageTracker>
    );
};
