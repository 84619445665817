import {
    Flex,
    HStack,
    Text,
    useColorModeValue,
    useMediaQuery,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { SocialIcon } from 'react-social-icons';
import { GenversePageLink } from '../../../../../../types';
import useGenversePage from '../../../../hooks/useGenversePage';
import useTrackEvent, {
    CLICKED_METAPAGE_LINK_EVENT,
} from '../../../../g3lytics/useTrackEvent';
import resolveENS from '../../../../utils/resolveENS';

export default ({ walletAddress }: { walletAddress: string }) => {
    const { genversePage } = useGenversePage({ address: walletAddress });
    const { trackEvent } = useTrackEvent();

    const [userLinks, setUserLinks] = useState([] as GenversePageLink[]);

    useEffect(() => {
        if (genversePage?.page?.platformLinks?.length !== userLinks?.length)
            setUserLinks(genversePage?.page?.platformLinks || []);
    }, [genversePage]);

    const onClickUserLink = async (userLink: GenversePageLink) => {
        const { address } = await resolveENS(walletAddress);

        await trackEvent(CLICKED_METAPAGE_LINK_EVENT, {
            address,
            pageLink: userLink,
        });
    };
    const [isMobile] = useMediaQuery('(max-width: 768px)');

    return (
        <Flex align="center" justify="center" wrap="wrap">
            {userLinks.map((userLink: GenversePageLink) => (
                <a
                    key={userLink.label}
                    href={userLink.url}
                    onClick={() => onClickUserLink(userLink)}
                    target="_blank"
                    rel="noreferrer"
                >
                    <HStack
                        align="center"
                        justify="center"
                        padding=".5rem 1rem"
                    >
                        <SocialIcon
                            bgColor={useColorModeValue(
                                'rgba(0, 0, 0, 0.8)',
                                'rgba(255, 255, 255, 0.8)'
                            )}
                            fgColor={useColorModeValue('white', 'black')}
                            url={userLink.url}
                            style={
                                isMobile
                                    ? {
                                          width: '2rem',
                                          height: '2rem',
                                      }
                                    : {
                                          width: '2.5rem',
                                          height: '2.5rem',
                                      }
                            }
                            target="_blank"
                        />
                        <Text fontSize={['0.8rem', '1rem']}>
                            {userLink.label}
                        </Text>
                    </HStack>
                </a>
            ))}
        </Flex>
    );
};
